<template>
<div id="menu" class="nav" > 
    <div class="logo">
      <router-link to="/"><img :src="logo" alt="Piracouro" title="Piracouro"/></router-link>
    </div>
    <div class="smallmenu" @click="menuClick()" v-if="smallMenu"><el-button icon="el-icon-menu" circle></el-button></div>
    <div v-if="!smallMenu"> 
      <router-link to="/about">Contato </router-link> 
      <router-link to="/status">Consultar Serviço </router-link>
      <router-link to="/">Home </router-link>
    </div>
</div>
<div v-show="showMenu" class="menu-mobile nav"> 
  <router-link to="/">Home </router-link>
  <router-link to="/status">Consultar Serviço </router-link>
  <router-link to="/about" >Contato </router-link> 
</div>
<div class="view">  
<router-view>
</router-view>
</div>
<div class="footer"> COMERCIO DE COUROS ZONETE LTDA | CNPJ 71.984.181/0001-86</div>
</template>
<script>
export default {
 data() {
    return {
      smallMenu: true,
      menuIsHide: true,
      logo: require('./assets/logoheader.png')
    }
  },
  computed:{
    showMenu : function(){
      return !this.menuIsHide && this.smallMenu
    }
  },
  watch: {
    '$route' (to, from) {      
      this.menuIsHide = true;
    }
  },
  mounted() {
      window.addEventListener('resize', () => {
        this.smallMenu = window.innerWidth < 600
      })
      this.smallMenu = window.innerWidth < 600;
    
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods:{
    menuClick: function(){
      this.menuIsHide = !this.menuIsHide; 
    }
  }
}
</script>

<style>
@font-face {
  font-family: JosefinSans-Bold;
  src: url('assets/JosefinSans-Bold.ttf');
}

@font-face {
  font-family: JosefinSans-Regular;
  src: url('assets/JosefinSans-Regular.ttf');
}

@font-face {
    font-family: 'StopRegular';
    src: url('assets/StopRegular.woff2') format('woff2'),
        url('assets/StopRegular.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

.menu-mobile{
  background-color: #000000;
  display: flex;
  flex-direction: column;
  padding: 5px;
  
}

.el-input-group__append {
  color: #e8c13b!important;
  background-color: #000!important;
}

.el-input__inner{
  color: #000!important;
  text-transform: uppercase !important;
  font-family: 'JosefinSans-Regular','Cormorant SC', serif !important;
}

.logo img{
  margin-top:20px !important;
}
.menu-mobile a{
  border: 0px solid  !important;
  margin-top: 10px;
  margin-bottom: 15px;
  
}
.view{
  background-color:rgba(247, 247, 247, 0.953) ;
  min-height: 85%;
  margin-top:10px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding: 10px;
  border-radius: 10px;
  
}
#menu{
  display: flex;  
  justify-content: space-between;
  background-color: #000000;
  align-items: center;
  
}
.smallmenu span{
  font-size: 30px;
  line-height: 30px;
}

.footer{
    color: #e8c13b;
    background: #000000;
    font-size: 12px;
    width: 80%;
    margin: auto;
    padding: 10px;
    margin-top: -12px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

#app {
  font-family: 'JosefinSans-Regular','Cormorant SC', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
}

.nav a {
  font-weight: bold;
  color: #e8c13b;
  float: right;
  padding: 0px 10px;
  border-left: 1px solid ;
  text-decoration: none;
}
.nav a:last-child {
  border-left: 0px solid !important;  
}

.logo {
  float:  left;
  font-family: 'StopRegular';
  font-weight: 100;
  font-size: 3em;
  font-style: normal;
  padding:0px 20px;
  margin-top: -10px;
  color: e8c13b

}
.nav div:not(.logo) >  a.router-link-exact-active {  
  text-decoration: underline solid 2px e8c13b
  
}

html, body{
  margin: 0px !important;
  height: 100% !important;
}

html { 
  background: url("assets/bg.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

</style>

<template>
  
    <p><strong>
      Há 70 anos no mercado de Piracicaba, <br />
      somos referência em vendas de artigos em couro e tapeçaria, <br />
      além de consertos de sapatos, bola, bolsas, mala & outros.<br />
     </strong> 
    </p>

    <el-carousel v-if="!smallMenu" :interval="3000" type="card" class="carousel" height="500px" >
      <el-carousel-item v-for="item in items" :key="item.id">
        <div>
          <img :src="item.img" />
          <div class="label" style="display:none">{{ item.desc }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <el-carousel v-if="smallMenu" :interval="3000"  arrow="always" class="carousel" height="500px" >
      <el-carousel-item v-for="item in items" :key="item.id">
        <div>
          <img :src="item.img" />
          <div class="label" style="display:none">{{ item.desc }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  
</template>


<style >
.carousel{
  height: 100%;
}
h2.is-active {
    color: e8c13b!important;
}
.label {
  font-weight: bold;
}
.is-active .label {
    display: block !important;
}

.carousel img{
  height: 80%;
}

.carousel div{
  height: 100%;
}


.home{
  height: 100%;
  display: block;
}
</style>

<script>

export default {
  data(){
    return {
      smallMenu: true,
      items:[
        { img: require('../assets/1.png'), desc: "Temos cintos 100% couro"  },
        { img: require('../assets/2.png') , desc: "Sandálias de couro"},
        { img: require('../assets/3.png') , desc: "Artigos para couros"},
        { img: require('../assets/4.png') , desc: "Consertos de Bolsas, Sapatos e Bola"},
        { img: require('../assets/5.png') , desc: "Fabricamos Capas para Celular e Bainhas"},
        { img: require('../assets/6.png') , desc: "Venha fazer uma visita.  Rua: Benjamin Constant ,1826 Piracicaba/SP" }
      ] 
    }
  },
  mounted() {
      window.addEventListener('resize', () => {        
        this.smallMenu = window.innerWidth < 600
      })
      this.smallMenu = window.innerWidth < 600;
    
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },
  name: 'Home',
  }
</script>
